import React, { Fragment, useState, useContext, useEffect } from 'react';
import {
    Typography,
    IconButton,
    Popover,
    makeStyles,
    Avatar,
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Link,
    Button,
    ClickAwayListener,
    Tooltip
} from '@material-ui/core';
import {
    useHistory,
    useLocation
} from 'react-router-dom';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { KeyboardArrowRightRounded, CloseRounded } from '@material-ui/icons';
import useComponentMountedHook from '../Hooks/useComponentMountedHook';
import UserApis from './Users/UserApis';
import GlobalStateContext from '../Context/GlobalStateContext';
import GlobalStateMutations from '../Context/GlobalStateMutations';
import { useAxios } from '../core/useAxios';
import { getImageUrl } from './AWS/AWSAssets';
import Axios from "axios";
import clsx from "clsx"
import Appstore from "../assets/images/appstore.svg"
import Playstore from "../assets/images/playstore.svg"
import EditIcon from "../assets/images/Icons/Edit.svg"
import LogoutIcon from "../assets/images/Icons/Logout.svg"
import HelpIcon from "../assets/images/Icons/Help.svg"
import { initPostHog } from "../Hooks/initPostHog";

// import _file from 'changelog.json'
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        minWidth: 240,
        fontSize: "1rem",
        background: "#161616",
        color: "#ffffff",
        boxShadow: "22px -18px 22px rgba(24, 39, 75, 0.12), 21px -11px 64px rgba(24, 39, 75, 0.12)",
        borderRadius: 10,
        height: 350,
        width: 250,
        zIndex: 1
    },

    name: {
        marginLeft: 12,
        fontWeight: 600,
        fontSize: 14
    },

    plain: {
        marginLeft: 12,
        fontSize: 11,
        color: "#6D6D6D"
    },

    avatar: {
        background: theme.palette.primary.main,
        color: "#FFFF",
        height: 30,
        width: 30
    },

    profile: {
        background: theme.palette.primary.main,
        color: "#FFFF"
    },

    button: {
        fontSize: 14,
        paddingLeft: "8px !important"
    },

    link: {
        fontSize: 14,
        paddingLeft: "6px !important",
        textDecoration: "none !important",
    },

    mr10: {
        marginRight: 10
    },

    editButtonContainer: {
        display: "flex",
        alignItems: "center"
    },

    version: {
        display: 'flex',
        fontWeight: 400,
        lineHeight: '11px',
        padding: '13px',
        fontSize: 11,
        justifyContent: 'center',
        color: "#6D6D6D",
        borderBottom: "0.5px solid rgba(255, 255, 255, 0.3)",
        marginBottom: 13,
    },

    nav: {
        paddingBottom: 0,
    },

    listIcon: {
        marginRight: 15,
        minWidth: 0,
    },

    mt10: {
        marginTop: 10,
    },

    bottom: {
        background: "#EFEFEF",
        height: 57,
        position: "absolute",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        bottom: 0,
        color: "#6D6D6D",
        padding: ({ isDrawerVisible }) => `12px 12px 12px ${isDrawerVisible ? "13px" : "20px"}`,
        width: "100%"
    },

    avtarButton: {
        "& .MuiIconButton-label": {
            height: 30,
            width: 30
        }
    },

    popover: {
        // display:"none",
        position: "absolute",
        bottom: "3px !important",
        background: "#161616",
        borderRadius: 10,
        overflow: "hidden",
        zIndex: 1000,
    },

    long: {
        left: "201px !important",
        top: "auto !important",
        '&::before': {
            content: '""',
            position: "fixed",
            transform: "rotate( 224deg )",
            bottom: "20px",
            border: "7px solid #161616",
            left: "194px",
        }
    },

    short: {
        left: "72px !important",
        '&::before': {
            content: '""',
            position: "fixed",
            transform: "rotate( 224deg )",
            bottom: "20px",
            border: "7px solid #161616",
            left: "65px",
        }
    },

    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "0.5px solid rgba(255, 255, 255, 0.3)",
        paddingBottom: "10px",
        justifyContent: "space-between"
    },

    text: {
        color: "#ffffff",
        fontWeight: 600,
        fontSize: 14
    },

    icon: {
        color: "#6D6D6D"
    },

    footer: {
        position: "absolute",
        bottom: "0"
    },

    nuovo: {
        fontSize: "11px",
        color: "#6D6D6D",
        margin: "10px 0px"
    },

    iconOpen: {
        zIndex: 1000,
        padding: 0,
        border: "2px solid #6D6D6D",
    },

    store: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        marginBottom: 21
    },

    parent: {
        '&:hover #profile': {
            display: "block",
            animation: "fade-out 1s"
        },
        '&:hover #icon': {
            background: "#161616",
            color: "#ffffff",
            animation: "fade-out 1s",
            border: "2px solid #161616",
        },
    },

    listGutter: {
        padding: "11px 13px 11px 8px",
    },

    linkText: {
        position: "relative",
        left: -19
    },

    textOverFLow: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },

    closeButton: {
        color: "#ffffff",
        border: "1px solid",
        borderColor: '#ffffff',
        height: 22,
        borderRadius: 12,
        cursor: "pointer",
        padding: "0px !important",
        minWidth: "22px !important"
    },

    buttonPosition: {
        position: "relative",
        top: "-18px",
        left: "9px",
    }
}));

function ListItemLink(props) {
    return <ListItem button component="button" {...props} />;
}

export default props => {
    const { oidcUser, logout, events } = useReactOidc();
    const location = useLocation();
    const [state, dispatch] = useContext(GlobalStateContext)
    const axiosInstance = useAxios(
        process.env.REACT_APP_KC_BROKER_URL,
        oidcUser.access_token
    );

    const { userProfileDetails } = state
    const classes = useStyles({ isDrawerVisible: state.isDrawerVisible });
    let history = useHistory();
    const [avatarImage, setAvatarImage] = useState(null)
    const { profile } = oidcUser;
    const [showProfile, setShowProfile] = useState(false)
    const [changeLog, setChangeLog] = useState({});

    const { isComponentMounted } = useComponentMountedHook(axiosInstance)

    useEffect(() => {
        let isProd = process.env.REACT_APP_IS_PRODUCTION
        if (userProfileDetails.uuid && !localStorage.getItem("loggedIn") && isProd == "true") {
            initPostHog().identify(
                userProfileDetails.uuid,
                {
                    'name': `${userProfileDetails.first_name} ${userProfileDetails.last_name}`,
                    'First Name': userProfileDetails.first_name,
                    'Last Name': userProfileDetails.last_name,
                    'email': userProfileDetails.email,
                },
            );
            localStorage.setItem("loggedIn", true)
        }
    }, [userProfileDetails])

    useEffect(() => {
        if (isComponentMounted) {
            UserApis.getUserProfile(axiosInstance)
                .then((res) => {
                    // Set user details in global state
                    dispatch({
                        type: GlobalStateMutations.SET_USER_PROFILE_DETAILS,
                        payload: res.data
                    })

                })
        }
    }, [isComponentMounted]);

    useEffect(() => {
        if (userProfileDetails.profile_picture) {
            getImageUrl(oidcUser, userProfileDetails).then((res) => setAvatarImage(res))
        }
    }, [userProfileDetails])

    // Get the changelog data
    useEffect(() => {
        Axios.get("changelog.json").then((re) => setChangeLog(re.data));
    }, []);

    // TODO: error handling
    function logOut() {
        localStorage.removeItem("dialogDate")
        localStorage.removeItem("loggedIn")
        logout();
    }

    function gotoChangelog() {
        history.push("/changelogs");
    }

    const openProfile = () => {
        //for billing tab card
        let isPriceCard = document.getElementById("price_card")
        if (isPriceCard) isPriceCard.style.zIndex = 0
        setShowProfile(true)
    }

    const closeProfile = () => {
        //for billing tab card
        let isPriceCard = document.getElementById("price_card")
        if (isPriceCard) isPriceCard.style.zIndex = 1
        setShowProfile(false)
    }


    /**
     * @returns {Boolean} - weather this is a prod build or not
     */
    const isProduBuild = () => process.env.REACT_APP_IS_PRODUCTION === "true"

    const renderVersion = () => {
        return isProduBuild() ? (
            <Box className={classes.version}>v{changeLog.list && changeLog.list[0].version}</Box>
        ) : (
            <Box onClick={gotoChangelog} className={classes.version}>
                v{changeLog.list && changeLog.list[0].version}
            </Box>
        );
    }

    const redirectTo = (link) => {
        if (link) history.push(link)
        else {
            logOut();
        }
    }

    const RouteLink = ({ lable, icon, link }) => {
        return <ListItemLink onClick={() => redirectTo(link)} className={classes.button}>
            <ListItemIcon className={classes.listIcon}>
                <img src={icon} />
            </ListItemIcon>
            <div className={classes.text}>{lable}</div>
        </ListItemLink>
    }
    const HelpLink = ({ lable, icon, link }) => {
        return <ListItem target="_blank" rel="noopener" href={link} component={Link} className={classes.link}>
            <ListItemIcon classesName={clsx(classes.listIcon)}>
                <img src={icon} />
            </ListItemIcon>
            <div className={clsx(classes.text, classes.linkText)}>{lable}</div>
        </ListItem>
    }

    return (
        <ClickAwayListener onClickAway={closeProfile}>
            <div className={classes.parent}>
                <div className={classes.bottom} onMouseOver={openProfile}>
                    <Box className={classes.textOverFLow}>
                        <IconButton edge="start" color="inherit" className={clsx(classes.mr10, classes.avtarButton)} size="small">
                            <Avatar src={avatarImage} className={classes.avatar} alt={profile.name}>
                                {profile.name.slice(0, 1).toUpperCase()}
                            </Avatar>
                        </IconButton>
                        {state.isDrawerVisible && <span className={classes.textOverFLow}>{profile.name.charAt(0).toUpperCase() + profile.name.slice(1)}</span>}
                    </Box>
                    {state.isDrawerVisible && <IconButton className={classes.iconOpen} size="small" id="icon" onClick={openProfile}>
                        <KeyboardArrowRightRounded fontSize="small" />
                    </IconButton>}
                </div>

                {showProfile && (
                    <div
                        id="profile"
                        className={clsx(classes.popover, state.isDrawerVisible ? classes.long : classes.short)}
                    >
                        <Box className={classes.root} >
                            <Box className={classes.header}>
                                <Avatar src={avatarImage} className={classes.profile} alt={profile.name}>
                                    {profile.name.slice(0, 1).toUpperCase()}
                                </Avatar>
                                <Box className={classes.textOverFLow} >
                                    <Typography className={clsx(classes.name, classes.textOverFLow)}>{profile.name.charAt(0).toUpperCase() + profile.name.slice(1)}</Typography>
                                    <Typography className={clsx(classes.plain, classes.textOverFLow)}>{profile.preferred_username}</Typography>
                                    {/* <Typography className={classes.plain}>{profile.orgname}</Typography> */}
                                </Box>
                                <Box className={classes.buttonPosition}>
                                    <Tooltip title="close" placement='right'>
                                        <Button className={classes.closeButton} onClick={closeProfile}>
                                            <CloseRounded fontSize='small' />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <List component="nav" className={classes.nav} aria-label="main account logout">
                                <RouteLink lable="Edit Profile" icon={EditIcon} link="/my-profile" />
                                <HelpLink lable="Help" icon={HelpIcon} link="https://help.mobilock.in/category/t6v0fct5bz-onteam" />
                                <RouteLink lable="Logout" icon={LogoutIcon} />
                            </List>
                            <Box className={classes.footer}>
                                {renderVersion()}
                                <div className={classes.nuovo}>Contact Us<br />
                                    Support : <a href="mailto:support@nuovoteam.com">support@nuovoteam.com </a><br />
                                    Sales : <a href="mailto:sales@nuovoteam.com">sales@nuovoteam.com</a>
                                </div>
                                <div className={classes.nuovo}>NuovoTeam Push-to-talk App is Available on</div>
                                <Box className={classes.store}>
                                    <a href="https://play.google.com/store/apps/details?id=com.promobitech.oneteam" target="_blank">
                                        <img src={Playstore} />
                                    </a>
                                    <a href="https://apps.apple.com/us/app/scalefusion-oneteam/id1513933869" target="_blank">
                                        <img src={Appstore} />
                                    </a>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};
